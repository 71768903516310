import { css, SerializedStyles } from '@emotion/react';
import { coerceCssPixelValue } from './utils';

export const width100 = css`
  width: 100%;
`;

export const height100 = css`
  height: 100%;
`;

type CSSPixelValue = string | number;

interface BoxSpacingAll {
  top?: CSSPixelValue;
  bottom?: CSSPixelValue;
  left?: CSSPixelValue;
  right?: CSSPixelValue;
}

interface BoxSpacingXY {
  x?: CSSPixelValue;
  y?: CSSPixelValue;
}

export type BoxSpacingOption = BoxSpacingAll | BoxSpacingXY | CSSPixelValue;

const isBoxSpacingAll = (option: BoxSpacingOption): option is BoxSpacingAll =>
  typeof option === 'object' &&
  ((option as BoxSpacingAll)?.top != null ||
    (option as BoxSpacingAll)?.bottom != null ||
    (option as BoxSpacingAll)?.left != null ||
    (option as BoxSpacingAll)?.right != null);

const isBoxSpacingXY = (option: BoxSpacingOption): option is BoxSpacingXY =>
  typeof option === 'object' &&
  ((option as BoxSpacingXY)?.x != null || (option as BoxSpacingXY)?.y != null);

export function spacing(cssProperty: string, option: BoxSpacingOption): SerializedStyles {
  let cssValue = '';

  if (isBoxSpacingAll(option)) {
    if (option.top != null) {
      cssValue += `${cssProperty}-top: ${coerceCssPixelValue(option.top)};`;
    }

    if (option.right != null) {
      cssValue += `${cssProperty}-right: ${coerceCssPixelValue(option.right)};`;
    }

    if (option.bottom != null) {
      cssValue += `${cssProperty}-bottom: ${coerceCssPixelValue(option.bottom)};`;
    }

    if (option.left != null) {
      cssValue += `${cssProperty}-left: ${coerceCssPixelValue(option.left)};`;
    }

    return css(cssValue);
  } else if (isBoxSpacingXY(option)) {
    if (option.x != null) {
      cssValue += `${cssProperty}-left: ${coerceCssPixelValue(option.x)};`;
      cssValue += `${cssProperty}-right: ${coerceCssPixelValue(option.x)};`;
    }

    if (option.y != null) {
      cssValue += `${cssProperty}-top: ${coerceCssPixelValue(option.y)};`;
      cssValue += `${cssProperty}-bottom: ${coerceCssPixelValue(option.y)};`;
    }

    return css(cssValue);
  }

  return css`
    ${cssProperty}: ${coerceCssPixelValue(option)};
  `;
}

type SpacingProperty = 'x' | 'y' | 'top' | 'right' | 'bottom' | 'left';
type SpacingUnit = 4 | 8 | 16 | 24 | 32;

export type BoxSpacing = {
  (option: BoxSpacingOption): SerializedStyles;
} & Readonly<Record<`${SpacingProperty}${SpacingUnit}`, SerializedStyles>>;

const properties: SpacingProperty[] = ['x', 'y', 'top', 'right', 'bottom', 'left'];
const units: SpacingUnit[] = [4, 8, 16, 24, 32];

function createSpacingWithProperty(cssProperty: string) {
  const _spacing = (option: BoxSpacingOption) => spacing(cssProperty, option);

  for (const property of properties) {
    for (const unit of units) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (_spacing as any)[`${property}${unit}`] = _spacing({
        [property]: unit,
      });
    }
  }

  return _spacing as BoxSpacing;
}

export const padding = createSpacingWithProperty('padding');
export const margin = createSpacingWithProperty('margin');
